'use strict'

export const PixelatedField = {
  extend: 'PixelatedFrame',
  tag: 'label',
  props: {},

  Input: {
    ignoreChildExtend: true,
    required: true,
    fontSize: 'Z',
    padding: 'A2 B2',
    background: 'transparent',
    color: 'white',
    round: '0',
    theme: 'transparent',
    placeholder: 'Name'
  }
}

export const PixelatedTextarea = {
  extend: PixelatedField,
  props: {
    width: '45%'
  },
  Input: null,

  Textarea: {
    required: true,
    maxWidth: 'none',
    ignoreChildExtend: true,
    padding: 'A2 B2',
    background: 'transparent',
    color: 'white',
    round: '0',
    fontSize: 'Z',
    theme: 'transparent',
    placeholder: 'Message'
  }
}

export const PixelatedButton = {
  extend: 'PixelatedFrame',

  props: {
    padding: '0',
    background: 'blue',
    borderColor: 'blue'
  },

  childExtend: {
    props: {
      ':after, &:before': {
        background: 'blue'
      }
    }
  },

  Button: {
    round: 'A',
    padding: 'A1 C',
    position: 'relative',
    zIndex: 2,
    ignoreChildExtend: true,
    theme: null,
    color: 'white',
    background: 'blue',
    type: 'submit',
    text: 'Send',
    fontSize: 'Z'
  }
}
