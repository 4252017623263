'use strict'

import { About } from './About'
import { About2 } from './About2'
import { ComingSoon } from './ComingSoon'
import { Main } from './Main'
import { Events } from './Events'
import { Privacy } from './Privacy'
import { Contact } from './Contact'
// import { Controller } from './Controller'

export default {
  '/': Main,
  '/coming-soon': ComingSoon,
  '/events': Events,
  '/about': About,
  '/about2': About2,
  '/privacy': Privacy,
  '/contact': Contact
}
