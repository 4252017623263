'use strict'

import { Grid, Flex } from 'smbls'
import cursorWhite from '../../assets/cursor_white_sm.svg'

export const Controller = {
  extend: Flex,

  props: () => ({
    align: 'stretch space-between',
    class: 'controller',
    borderRadius: 'Z',
    gap: 'A1',
    templateColumns: '1fr 1fr 1fr 1fr 1fr',
    position: 'absolute',
    inset: '0 0 0 16vw',
    zIndex: 16,

    hide: window.location.pathname !== '/'
  }),

  DrumPads: {
    extend: Grid,
    props: {
      class: 'drum-pads',
      position: 'relative',
      flex: 1,
      padding: 'E2 A A C',
      gap: 'C3',
      columns: 'repeat(4, 1fr)',
      '@tabletS': {
        gridColumnStart: 'span 5',
        columns: 'repeat(3, 1fr)'
      }
    },

    childExtend: {
      props: {
        class: 'drum-pad'
      }
    },

    'Pinky.cymbal': {},
    'Clyde.maracas': {},
    'Blinky.zap': {},
    'Inky.sample': {},
    'Cherry.hat-closed': {},
    'BlinkyAngry.hat-open': {},
    'Bell.tom-high': {},
    'Key.tom-low': {},
    'Galaxian.kick1': {},
    'Apple.kick2': {},
    'Melon.snare': {},
    'Orange.rim': {}
  },

  Effects: {
    extend: Flex,
    props: {
      class: 'disabled effects-container',
      gap: 'A2',
      alignItems: 'center',
      gridColumnStart: 'span 2',
      flexDirection: 'column',
      padding: 'B1',
      userSelect: 'none',
      background: '#0C0C0CAA',
      backdropFilter: 'blur(3px)',
      transform: 'translate3d(0, 0, 1px)',
      opacity: 1,
      transition: 'B defaultBezier',
      transitionProperty: 'opacity, transform',

      // '&.disabled': {
      //   transform: 'translate3d(100%, 0, 1px)',
      //   opacity: 0
      // },

      '@tabletS': {
        gridColumnStart: 'span 5',
        gridRowStart: '5',
        padding: 'B B D'
      }
    },

    Screen: {
      extend: 'Flex',
      props: {
        width: '100%',
        align: 'center space-between'
      },

      Text: {
        class: 'screen',
        width: '100%',
        // margin: 'X 0 0',
        position: 'relative',
        textAlign: 'center',
        opacity: '.55',
        fontFamily: 'game',
        padding: 'A',
        background: 'white 0.15',
        text: 0
      }
    },

    Grid_bpm: {
      props: {
        columns: 'repeat(3, 1fr)',
        padding: 'A',
        gap: 'C3+Y D+X',
        '@tabletS': {
          gap: 'D3'
        },
        '@mobileS': {
          gap: 'E1'
        }
      },

      Knob: {
        props: {},
        Text: { text: 'BPM' }
      }
    },

    Hr: { minWidth: '100%', opacity: '.065', margin: 'A -B' },

    Grid_ctrls: {
      props: {
        class: 'effects disabled',
        padding: 'A',
        columns: 'repeat(3, 1fr)',
        gap: 'C3+Y D+X',
        '@tabletS': {
          gap: 'D3'
        },
        '@mobileS': {
          gap: 'E1'
        },
        '&.disabled .knob': {
          pointerEvents: 'none',
          opacity: '.25'
        }
      },
      childExtend: 'Knob',
      ...[
        {
          Text: { text: 'Freq' }
        },
        {
          Text: { text: 'Res' }
        },
        {
          Text: { text: 'Amp' }
        },
        {
          Text: { text: 'Delay' }
        },
        {
          Text: { text: 'Feedback' }
        },
        {
          Text: { text: 'Filter' }
        },
        {
          Text: { text: 'Reverb' }
        },
        {
          Text: { text: 'Decay' }
        },
        {
          Text: { text: 'Pan' }
        }
      ]
    },

    Pass: {
      props: ({ state }) => ({
        hide: true,
        text: (state.pass === 'low' ? 'L' : 'H') + '-pass',
        onClick: (ev, el, s) =>
          s.update({
            pass: s.pass === 'low' ? 'high' : 'low'
          })
      })
    }
  },

  Flex: {
    props: {
      left: 0,
      bottom: 'A2',
      position: 'absolute',
      align: 'center',
      gap: 'B2',
      gridColumnStart: 'span 5',
      style: {
        background: 'none',
        border: 'none'
      }
    },

    MainControls: {
      extend: Flex,
      state: {},
      props: {
        gap: 'X',
        margin: '- - - -Z2',
        position: 'relative',
        zIndex: 3
      },
      childExtend: {
        extend: 'SquareButton',
        props: {
          fontSize: 'C',
          padding: 'Y',
          color: 'white',
          background: 'transparent',
          cursor: `url(${cursorWhite}), pointer`
        }
      }
    },

    StepSequencer: {
      extend: 'Grid',
      props: {
        class: 'step-sequencer',
        flex: 1,
        gap: 'B1',
        align: 'center',
        padding: '0 0 0 C2',
        position: 'relative',
        columns: 'repeat(16, 1fr)',
        minHeight: '50px',
        alignItems: 'center',
        overflow: 'hidden',

        '@tabletS': {
          padding: 'B B2 B D1-C',
          columns: 'repeat(8, 1fr)'
        }
      },

      childExtend: {
        props: {
          class: 'sequencer-step',
          position: 'relative',
          background: 'light',
          boxSize: 'Y',
          round: 'V',
          '&.active div': {
            display: 'block'
          },
          ':after': {
            content: '""',
            background: 'light 0.1 +16',
            boxSize: 'C',
            position: 'absolute',
            top: '50%',
            round: 'C1',
            opacity: 0,
            transition: 'opacity, defaultBezier, A',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 1px)'
          },
          ':hover': {
            ':after': {
              opacity: 1
            }
          }
        },
        Scared: {
          display: 'none',
          position: 'absolute',
          left: '50%',
          transform: 'translate3d(-50%, -50%, 1px)'
        }
      },

      Pacman: {
        ignoreChildExtend: true,
        position: 'absolute',
        id: 'pacman',
        top: '50%',
        left: 'A',
        borderRadius: '0',
        transform: 'translate3d(-20px, -50%, 1px)',
        zIndex: 2,
        transition: 'y transform linear',
        margin: '- C - -',
        pointerEvents: 'none',
        '@tabletS': {
          left: '0'
        },

        ':after': {
          content: '""',
          background: 'black .85',
          top: '0',
          bottom: '0',
          position: 'absolute',
          right: '100%',
          width: '100vw'
        }
      },

      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step6: {},
      step7: {},
      step8: {},
      step9: {},
      step10: {},
      step11: {},
      step12: {},
      step13: {},
      step14: {},
      step15: {},
      step16: {}
    }
  }
}
