'use strict'

import cursorWhite from '../assets/cursor_white_sm.svg'

const resizeImg = imgUrl => {
  if (window.location.hostname !== 'localhost') return `https://imgs-cache.symbo.ls/500/${imgUrl}`
  return imgUrl
}

export const Event = {
  props: {
    aspectRatio: '1 / 1',
    class: 'EventsSection__child',
    position: 'relative',
    // overflow: 'hidden',
    transition: 'B defaultBezier',
    transitionProperty: 'opacity, filter',
    cursor: `url(${cursorWhite}), auto`,
    style: { perspective: '1000px' },

    '.archived': {
      opacity: '.15',
      filter: 'grayscale(1)',

      ':hover': {
        '& section': {
          transform: 'rotateY(0)'
        }
      },

      ':active': {
        '& section': {
          transform: 'rotateY(180deg)'
        }
      }
      // mixBlendMode: 'luminosity'
    },

    ':hover': {
      filter: 'grayscale(0)',
      opacity: '1',

      '& section': {
        transform: 'rotateY(180deg)',
        '& img': {
          opacity: 1
        }
      },

      '& label': {
        transform: 'translate3d(-50%, 0, 1px)',
        opacity: '1'
      }
    }
  },

  Cards: {
    tag: 'section',

    props: {
      transition: 'E ease transform',
      style: {
        transformStyle: 'preserve-3d'
      }
    },

    Img_front: (_, state) => ({
      maxWidth: '100%',
      minHeight: '100%',
      position: 'relative',
      src: resizeImg(state.front),
      zIndex: 2,
      style: {
        backfaceVisibility: 'hidden'
      }
    }),

    Img_back: (_, state) => ({
      maxWidth: '100%',
      minHeight: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: 1,
      transform: 'rotateY(180deg)',
      src: resizeImg(state.back)
    })
  },

  Label: ({ state }) => ({
    position: 'absolute',
    pointerEvents: 'none',
    bottom: 'z',
    left: '50%',
    transform: 'translate3d(-50%, 100%, 1px)',
    transition: 'A ease-in',
    transitionProperty: 'opacity, transform',
    zIndex: 1,
    opacity: '0',
    padding: 'Y A1',
    borderRadius: 'A',
    background: 'dark',
    lineHeight: '1',
    text: 'Archive',
    hide: !state.archived
  })
}
