'use strict'

import cursorWhite from '../assets/cursor_white_sm.svg'

export const VolumeSlider = {
  extend: ['Flex', 'Slider'],
  button0: null,
  button1: null,
  value: null,
  props: {
    align: 'center',
    cursor: `url(${cursorWhite}), pointer`,

    range: {
      cursor: `url(${cursorWhite}), pointer`,
      '::-webkit-slider-thumb': {
        boxSizing: 'content-box',
        cursor: `url(${cursorWhite}), pointer`,
        appearance: 'none',
        width: '5px',
        height: '15px',
        background: 'white'
      }
    }
  }
}
