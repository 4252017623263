'use strict'

export const Main = {
  extend: ['Page'],
  on: {
    render: (el, s) => {
      if (el.parent.data.p5instance) {
        el.parent.data.p5instance.setup()
      }
    }
  }
}
