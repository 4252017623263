import P5 from '../../modules/p5.min'
const getElementPosition = (p, el) => {
  const rect = el.getBoundingClientRect()
  return p.createVector(rect.left + rect.width / 2, rect.top + rect.height / 2)
}

let isHovering = false // Flag to track hover state
let elToAvoid = null // Element to avoid

const avoidElement = (p, particlePos) => {
  if (!elToAvoid) return p.createVector(0, 0) // Ensure the element exists

  const elPosition = getElementPosition(p, elToAvoid)
  const distance = p.dist(
    particlePos.x,
    particlePos.y,
    elPosition.x,
    elPosition.y
  )

  // Calculate a repulsion force if within a certain distance
  if (distance < elToAvoid.offsetWidth * 3) {
    // Adjust this threshold as needed
    const force = P5.Vector.sub(particlePos, elPosition) // Direction from element to particle
    force.normalize() // Normalize to get direction
    force.div(10) // Weaker force with greater distance
    return force
  }

  return p.createVector(0, 0) // No force if not close to the element
}

const addDivHoverListener = (div) => {
  div.addEventListener('mouseenter', function () {
    isHovering = true
    elToAvoid = div
  })
  div.addEventListener('mouseleave', function () {
    isHovering = false
    elToAvoid = null
  })
}

// Function to add event listeners
const setupDivHoverListener = (className) => {
  const divs = document.querySelectorAll(className)
  if (!divs) return
  for (let i = 0; i < divs.length; i++) {
    addDivHoverListener(divs[i])
  }
}

class Particle {
  constructor(p) {
    this.p = p
    this.reset()
  }

  reset() {
    this.pos = this.p.createVector(
      this.p.random(this.p.windowWidth),
      this.p.random(this.p.windowHeight)
    )
    this.vel = this.p.createVector(0, 0)
    this.size = this.p.random(1, 3)
    this.noiseOffsetX = this.p.random(1000)
    this.noiseOffsetY = this.p.random(1000)
    this.windInfluence = this.p.random(0.02, 0.4) // Varying wind influence
    this.windChangeTimer = this.p.floor(this.p.random(60, 240)) // Random timer for changing wind response
  }

  move(wind) {
    // Decrease timer and possibly change wind influence
    if (this.windChangeTimer <= 0) {
      this.windInfluence = this.p.random(0.02, 0.1) // Change wind influence randomly
      this.windChangeTimer = this.p.floor(this.p.random(60, 240)) // Reset timer
    } else {
      this.windChangeTimer--
    }

    // Slow and natural movement with noise
    this.noiseOffsetX += 0.01
    this.noiseOffsetY += 0.01

    const noiseStrength = 0.1
    this.vel.x +=
      (this.p.noise(this.noiseOffsetX) - 0.5) * noiseStrength +
      wind.x * this.windInfluence
    this.vel.y +=
      (this.p.noise(this.noiseOffsetY) - 0.5) * noiseStrength +
      wind.y * this.windInfluence

    // Apply the velocity to the position if hovering
    if (isHovering) {
      const avoidanceForce = avoidElement(this.p, this.pos)
      this.vel.add(avoidanceForce) // Apply avoidance force to velocity
    }

    // Apply the velocity to the position
    this.pos.add(this.vel)

    // Limit the velocity to keep movement gentle
    this.vel.limit(0.2)

    // Reset particle if it goes off the screen
    if (
      this.pos.x < 0 ||
      this.pos.x > this.p.windowWidth ||
      this.pos.y < 0 ||
      this.pos.y > this.p.windowHeight
    ) {
      this.reset()
    }
  }

  display(lightSource) {
    // Calculate distance to the light source (e.g., mouse position)
    const d = this.p.dist(this.pos.x, this.pos.y, lightSource.x, lightSource.y)
    const maxDist = this.p.dist(0, 0, this.p.width / 2, this.p.height / 2)

    // Adjust opacity and size based on distance to the light source
    const opacity = this.p.map(d, 0, maxDist, 200, 10) // More visible closer to the light
    const sizeFactor = this.p.map(d, 0, maxDist, 1.5, 1) // Larger closer to the light

    // Main particle
    this.p.noStroke()
    this.p.fill(255, opacity)
    this.p.circle(this.pos.x, this.pos.y, this.size * sizeFactor)

    // Glow effect
    const glowSize = this.size * sizeFactor
    this.p.fill(255, opacity / 2) // The glow is more subtle
    this.p.circle(this.pos.x, this.pos.y, glowSize)

    this.color = this.p.color(
      this.p.random(200, 255),
      this.p.random(200, 255),
      this.p.random(200, 255)
    )
  }
}

const NUM_PARTICLES = 400
const particles = []
// In your sketch, calculate the wind vector and pass it to the move function of each particle
let currentLightSource

export const DustParticles = {
  setup: (p) => {
    currentLightSource = p.createVector(p.width / 2, p.height / 2)
    // Call this function in your setup or initialization code
    p.createCanvas(p.windowWidth, p.windowHeight)
    if (particles.length >= NUM_PARTICLES) return

    setupDivHoverListener('.drum-pad')
    window.addEventListener('resize', () => {
      p.resizeCanvas(window.innerWidth, window.innerHeight)
      for (let i = 0; i < particles.length; i++) {
        particles[i].reset()
      }
    })

    for (let i = 0; i < NUM_PARTICLES; i++) {
      particles.push(new Particle(p))
    }
  },
  draw: (p) => {
    // p.background('rgba(0,0,0,1)')
    // p.background(0, 5)
    p.clear()
    const wind = P5.Vector.sub(
      p.createVector(p.mouseX, p.mouseY),
      p.createVector(p.width / 2, p.height / 2)
    )
    wind.normalize()
    wind.mult(0.1) // Reduce the overall influence of wind

    // Check if mouse is inside the canvas
    let targetLightSource
    if (
      p.mouseX > 20 &&
      p.mouseX < p.width - 20 &&
      p.mouseY > 20 &&
      p.mouseY < p.height - 20
    ) {
      targetLightSource = p.createVector(p.mouseX, p.mouseY)
    } else {
      // If mouse is outside canvas, set light source to center
      targetLightSource = p.createVector(p.width / 2, p.height / 2)
    }
    currentLightSource.x = p.lerp(
      currentLightSource.x,
      targetLightSource.x,
      0.03
    )
    currentLightSource.y = p.lerp(
      currentLightSource.y,
      targetLightSource.y,
      0.03
    )

    // Update and display particles
    for (const particle of particles) {
      particle.move(wind)
      particle.display(currentLightSource)
    }
  }
}
