'use strict'

export const Events = {
  props: {},

  state: 'events',

  Flex: {
    props: {
      flow: 'column',
      gap: 'E2',
      userSelect: 'none'
    },

    childExtend: {
      extend: 'Grid',

      props: {
        gap: 'D1',
        align: 'center',
        templateColumns: 'repeat(3, 1fr)',
        padding: 'C1 D1',
        fontFamily: 'ui'
      },

      childExtend: 'Event'
    },

    Upcoming: {
      props: {},
      $stateCollection: ({ state }) => state.data.filter(v => !v.archived)
    },

    Archived: {
      props: {},
      $stateCollection: ({ state }) => state.data.filter(v => v.archived)
    }
  }
}
