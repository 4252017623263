'use strict'

import P5 from './modules/p5.min'
import './modules/p5.sound.min'

import { createSync } from 'smbls'
import { FastClick } from 'fastclick'

import designSystem from './designSystem'
import * as components from './components'
import state from './state'
import pages from './pages'

// import { SpaceParticles } from './components/SpaceParticles/utils'
import { DrumMachine } from './components/Controller/utils'
import { DustParticles } from './components/DustParticles/utils'

import cursor from './assets/cursor_sm.svg'
import cursorWhite from './assets/cursor_white_sm.svg'

createSync({
  extend: 'Flex',

  data: {
    // preload: [DrumMachine.preload],
    setup: [DustParticles.setup, DrumMachine.setup],
    draw: [DustParticles.draw],
    p5instance: null
  },

  props: {
    theme: 'document',
    flow: 'row',
    boxSize: '100dvh 100dvw',
    align: 'start space-between',
    position: 'absolute',
    inset: '0 0 0 0',
    overflow: 'hidden auto',
    cursor: `url(${cursor}), auto`,

    '& button, & a, & button *, & a *': {
      cursor: `url(${cursorWhite}), auto`
    },

    ':after': {
      content: '""',
      position: 'fixed',
      top: '0',
      left: '0',
      boxSize: '100%',
      zIndex: 10,
      pointerEvents: 'none',
      background:
          'radial-gradient(closest-side, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 25%, rgba(0,0,0,.85) 100%)'
    },

    content: {
      flow: 'column',
      align: 'center space-between',
      flex: '1 1 auto',
      padding: 'C1',
      minHeight: '100%',
      position: 'relative',
      zIndex: 15,
      order: 2
    },

    '& .p5Canvas': {
      position: 'fixed',
      inset: '0 0 0 0',
      zIndex: 0
    }
  },

  Navigation: {},
  Transition: {},
  Controller: {},
  DustParticles: { hide: true },

  InfoButton: {
    extend: 'Link',
    props: {
      href: '/privacy',
      zIndex: 999,
      position: 'fixed',
      right: 'A',
      bottom: 'A'
    }
  },

  on: {
    render: (el, s) => {
      const p5instance = new P5((p) => {
        p.setup = () => {
          return el.data.setup.forEach((callback) => callback(p))
        }
        p.draw = () => {
          return el.data.draw.forEach((callback) => callback(p))
        }
      }, el.node)
      el.data.p5instance = p5instance
      return p5instance
    }
  }
}, {
  state,
  designSystem,
  components,
  pages,
  editor: {
    liveSync: true,
    remote: true,
    async: true
  }
})

FastClick.attach(document.body)
