'use strict'

export const About = {
  props: {
  },

  Content: {
    props: {
      margin: '72vh - -',
      background: 'black .85',
      backdropFilter: 'blur(3px)',
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      opacity: '.9',
      width: '100%',
      maxWidth: 'I3'
    },

    H5: {
      text: 'STAUB - an event series in Berlin'
    }

    // P: {
    //   text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    // }
  },

  Content_2: {
    extend: 'Flex',
    props: {
      flow: 'column',
      background: 'black .85',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      backdropFilter: 'blur(3px)',
      maxWidth: 'I3',
      color: 'white .65'
    },

    Flex_1: {
      props: {
        gap: 'C',
        fontSize: 'A2',
        childProps: { flex: 1 }
      },

      Txt: {
        fontFamily: 'ui',
        text: 'It is known for its unique atmosphere and diverse musical offerings, spanning from Techno, House, and Disco to Ambient and experimental electronic music. Notably, STAUB hosts daytime parties that often extend into the evening.'
      },

      Txt_2: {
        fontFamily: 'ui',
        text: 'In addition to contributing to the vibrancy of Berlin\'s party scene, STAUB is committed to inclusivity and supporting newcomers and young talents, making it a significant hub of cultural and musical diversity.'
      }
    },

    Divider: { margin: 'auto' },

    P_2: {
      fontFamily: 'ui',
      text: 'It’s twilight on Monday, May 28 and the sun is setting over the Detroit skyline. A tired but exuberant crowd is dancing on the back patio of the downtown venue TV Lounge, an off-Movement Festival space that plays host to afterparties throughout the duration of the country’s largest and oldest electronic music event. But on this final day of the Memorial Day weekend, the crowd isn’t dancing to the more standard musical offerings of local talent or US-based techno artists; they’re dancing to STAUB, a Berlin-based party that only recently touched down in Detroit.'
    },

    Poster: {
      props: { margin: 'C1 0' },
      Img: {
        width: '100%',
        src: 'https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg',
        boxShadow: 'Z2, Z2, 0, 0, white .05'
      }
    },

    P_3: {
      fontFamily: 'ui',
      text: 'STAUB’s appearance in the Motor City was its first in the US, and its famously anonymous booking concept fell counter to the heavily promoted Movement festivities that were happening a few blocks away. That the undisclosed STAUB headliners included major acts that played at the much more heavily publicized—and costly—festival the weekend before was not received without a touch of irony from the guests who were able to identify them.'
    },

    Flex_soundcloud: {
      props: {
        width: '100%',
        margin: 'C 0',
        gap: 'Z',
        flow: 'column'
      },

      Iframe: {
        width: '100%',
        minHeight: 'none',
        height: '166px',
        scrolling: 'no',
        frameborder: 'no',
        allow: 'autoplay',
        src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/576853176&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
      },

      Italic: {
        fontSize: 'Z1',
        fontFamily: 'ui',
        text: 'Lorem inpsum from the guests who were able to identify them.'
      }
    },

    Form: {
      props: {
        margin: 'C1 - -',
        flexFlow: 'column',
        flexAlign: 'start start',
        flex: 1,
        gap: 'A',

        onSubmit: (ev, el) => {
          ev.preventDefault()
        }
      },

      P: {
        fontFamily: 'ui',
        text: 'Subscribe to the STAUB newsletter and unlock surprises in every edition! Enjoy exclusive drops like download codes for record releases, special artworks, stickers, and more. Don\'t miss out – be part of the STAUB experience!'
      },

      Flex: {
        props: {
          gap: 'Z',
          align: 'center center'
        },
        'PixelatedField.email': {
          Input: {
            type: 'email',
            placeholder: 'Email'
          }
        },

        PixelatedButton: {}
      }
    }
  }
}

export const AboutWithBackground = {
  props: {
    ':before': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'url(https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg) center center',
      backgroundSize: 'cover'
    },
    ':after': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'dark 1 -250'
    }
  },

  Flex: {
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center'
    },

    H3: {
      text: 'The monthly party at ://about blank is breaking with tradition by not announcing lineups.'
    },

    P: {
      text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    }
  }
}
