'use strict'

import P5 from '../../modules/p5.min'
import { PreloadSounds, BPM } from './preload'

const makeSound = (soundObj) => {
  return function (time, playbackRate) {
    soundObj.el.target.classList.add('active')
    setTimeout(() => {
      soundObj.el.target.classList.remove('active')
    }, 350)
    soundObj.sound.rate(playbackRate)
    soundObj.sound.play(time)
  }
}

let currentPart
let activePhrase
let phraseName
let sounds
let activePad = null
let dragControl = null

const dragMouse = { x: 0, y: 0 }

export const DrumMachine = {
  setup: (p) => {
    p.noCanvas()
    const controller = p.select('.controller')
    if (!controller) return
    sounds = PreloadSounds(p)
    const clearButton = p.select('.clear-button') // eslint-disable-line
    const body = p.select('body')
    const controllerEffectsContainer = p.select('.effects-container')
    const controllerEffectsSection = p.select('.effects')
    const screen = p.select('.screen')
    const startButton = p.select('.play-button')
    const stopButton = p.select('.stop-button')
    const sequencerSteps = p.selectAll('.sequencer-step')
    const pacman = p.select('#pacman')
    const padsContainer = p.select('.drum-pads')
    const pads = p.selectAll('.drum-pad')

    const updateEffectValues = (effect, changeY) => {
      const changeAmount = changeY * effect.step

      if (changeY <= 0) {
        effect.value = parseInt(
          effect.value > effect.max - 1
            ? effect.max
            : effect.default + -changeAmount
        )
      } else if (changeY > 0) {
        effect.value = parseInt(
          effect.value <= parseInt(effect.min)
            ? effect.min
            : effect.default - changeAmount
        )
      }
    }

    const rotateKnob = (effect, target) => {
      const rotationDegree =
        Math.abs(effect.min - effect.value) * (270 / (effect.max - effect.min))
      target.style.transform = `rotate(${rotationDegree - 135}deg)`
    }
    const updateScreen = (effect) => {
      if (screen) {
        screen.elt.innerText = effect.value
      }
    }
    const initializeSoundsWithSequenceAndEffects = (el) => {
      sounds[el].phrase = new P5.Phrase(
        el,
        makeSound(sounds[el]),
        sounds[el].pattern
      )
      sounds[el].effects.filter = new P5.Filter()
      // sounds[el].effects.highPassfilter = new P5.HighPass()
      sounds[el].effects.delay = new P5.Delay()
      sounds[el].effects.reverb = new P5.Reverb()
      sounds[el].sound.disconnect()

      sounds[el].sound.connect(sounds[el].effects.env)
      // connect soundFile to reverb, process w/
      // 3 second reverbTime, decayRate of 2%
      sounds[el].effects.reverb.process(sounds[el].sound, 0.1, 1)
      // delay.process() accepts 4 parameters:
      // source, delayTime (in seconds), feedback, filter frequency
      sounds[el].effects.delay.process(sounds[el].sound, 0, 0, 0)
      //
      sounds[el].sound.connect(sounds[el].effects.reverb)
      sounds[el].sound.connect(sounds[el].effects.filter)
      sounds[el].sound.connect(sounds[el].effects.delay)

      // sounds[el].effects.reverb.set(0, 0);
      // sounds[el].effects.delay.filter(0);
      // sounds[el].effects.delay.delayTime(0.1);
      // sounds[el].effects.delay.feedback(0.1);
      // sounds[el].effects.highPassfilter.amp(sounds[el].effects.env);
      // sounds[el].sound.amp(sounds[el].effects.env)
    }

    const stepSequencer = p.select('.step-sequencer')
    const windowWidth = p.windowWidth
    const initializePacmanSequence = () => {
      return new P5.Phrase(
        'pacman',
        () => {
          let pacmanStep =
            (stepCounter * stepSequencer.width) / 16 - pacman.width / 2
          if (windowWidth < 1024) {
            pacmanStep = ((stepCounter % 8) * stepSequencer.width) / 8
          }
          // move pacman to next step
          pacman.style(
            'transform',
            `translate3d(${-20 + pacmanStep}px, ${
              windowWidth < 1024 ? (stepCounter > 8 ? 0 : -95) : -50
            }%, 1px)`
          )

          stepCounter++
          if (stepCounter > 16) {
            pacman.style(
              'transform',
              `translate3d(${
                (stepCounter * stepSequencer.width) / 16
              }px, 150%, 1px)`
            )

            stepCounter = 1

            pacman.style(
              'transform',
              `translate3d(${
                -20 - (stepCounter - 1 * stepSequencer.width) / 16
              }px, 150%, 1px)`
            )

            pacman.style(
              'transform',
              `translate3d(${
                -20 - (stepCounter - 1 * stepSequencer.width) / 16
              }px, -50%, 1px)`
            )
          }
        },
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
      )
    }

    const initializeAudioContext = (el, p) => {
      // mimics the autoplay policy
      p.getAudioContext().suspend()
      el.mouseClicked(() => {
        if (p.getAudioContext().state === 'running') return
        p.userStartAudio()
      })
    }

    // initial BPM and screen setup
    updateScreen(BPM)
    rotateKnob(BPM, p.select("div[data-effect-name='bpm']").elt)

    // Start audio context on passed element click
    initializeAudioContext(controller, p)

    // initialize instrument parts
    if (!sounds) return
    Object.keys(sounds).forEach((el) => {
      initializeSoundsWithSequenceAndEffects(el)
    })

    // Initialize a new P5.Part object
    currentPart = new P5.Part(16)
    currentPart.setBPM(BPM.value)

    let stepCounter = 0
    const pacmanPhrase = initializePacmanSequence() // start stop button for the part

    startButton.mouseClicked(() => {
      if (currentPart != null) {
        if (!currentPart.getPhrase('pacman')) {
          currentPart.addPhrase(pacmanPhrase)
        }
        pacman.addClass('looping')
        window.requestAnimationFrame(() => {
          currentPart.loop()
        })
        startButton.addClass('active')
      }
    })
    stopButton.mouseClicked(() => {
      if (currentPart != null) {
        currentPart.stop()
        pacman.removeClass('looping')
        startButton.removeClass('active')
      }
    })

    // Handle sequencer steps
    sequencerSteps.forEach((el, i) =>
      el.mouseClicked(() => {
        if (activePhrase == null) return
        if (activePhrase.sequence[i]) {
          sounds[activePhrase.name].pattern[i] = 0
          currentPart.replaceSequence(
            activePhrase.name,
            sounds[activePhrase.name].pattern
          )
          el.removeClass('active')
        } else {
          sounds[activePhrase.name].pattern[i] = 1
          currentPart.replaceSequence(
            activePhrase.name,
            sounds[activePhrase.name].pattern
          )
          el.addClass('active')
        }
      })
    )

    const padsContainerWidth = padsContainer.elt.offsetWidth
    const padsContainerHeight = padsContainer.elt.offsetHeight
    const centerX = padsContainerWidth / 2
    const centerY = padsContainerHeight / 2
    const baseRadius = Math.min(centerX, centerY) * 0.5 // Base radius for the circle
    const angleStep = p.TWO_PI / pads.length
    let counter = 0

    pads.forEach((pad) => {
      phraseName = pad.attribute('id')
      pad.elt.style.backgroundImage = `url(${sounds[phraseName].image})`

      // // Random position from the center of the parent div
      // const x = centerX + p.random(-centerX, centerX) - pad.elt.offsetWidth / 2
      // const y = centerY + p.random(-centerY, centerY) - pad.elt.offsetHeight / 2
      // //

      // Introduce randomness in the radius and angle to scatter pads
      // const randomAngleOffset = p.random(-0.1, 0.1) // Small angle offset for scattering
      // const randomRadiusOffset = p.random(-50, 50) // Small radius offset for scattering
      //
      // const angle = angleStep * counter
      // const radius = baseRadius + randomRadiusOffset
      //
      // const x = centerX + radius * Math.cos(angle) - pad.elt.offsetWidth / 2
      // const y = centerY + radius * Math.sin(angle) - pad.elt.offsetHeight / 2

      // pad.elt.style.position = 'absolute'
      const randomChangeOffsetY = p.random(
        -pad.elt.offsetWidth,
        pad.elt.offsetWidth
      )
      const randomChangeOffsetX = p.random(
        -pad.elt.offsetWidth,
        pad.elt.offsetWidth
      )
      pad.elt.style.transform = `translate3d(${randomChangeOffsetX}px, ${randomChangeOffsetY}px, 1px)`
      //
      pad.mouseClicked((el) => {
        phraseName = pad.attribute('id')
        // Add el to sound object
        if (sounds[phraseName].el == null) {
          sounds[phraseName].el = el
          sounds[phraseName].sound.play()
        } else if (sounds[phraseName].pattern.every((el) => el === 0)) {
          sounds[phraseName].sound.play()
        }

        pads.forEach((p) => p.removeClass('recording'))
        pad.addClass('recording')

        Object.keys(sounds[phraseName].effectValues).forEach((effectName) => {
          const effect = sounds[phraseName].effectValues[effectName]
          rotateKnob(
            effect,
            p.select(`div[data-effect-name='${effectName}']`).elt
          )
        })
        if (
          currentPart.getPhrase(phraseName) == null &&
          sounds[phraseName].phrase != null
        ) {
          currentPart.addPhrase(sounds[phraseName].phrase)
          // if (looping) {
          pad.addClass('playing')

          activePad = pad
        } else if (activePad === pad) {
          currentPart.removePhrase(phraseName)
          // if (looping) {
          pad.removeClass('recording')
          pad.removeClass('playing')
          // }
          activePad = null
        } else {
          activePad = pad
        }

        if (activePad != null) {
          activePhrase = currentPart.getPhrase(activePad.attribute('id'))
          if (controllerEffectsSection)
            controllerEffectsSection.removeClass('disabled')
          if (controllerEffectsContainer)
            controllerEffectsContainer.removeClass('disabled')
          sequencerSteps.forEach((el, i) => {
            el.removeClass('active')
            if (activePhrase.sequence[i]) {
              el.addClass('active')
            }
          })
        } else {
          sequencerSteps.forEach((el) => el.removeClass('active'))
          controllerEffectsSection.addClass('disabled')
        }
      })
    })

    const knobs = p.selectAll('.tick')

    knobs.forEach((knob) => {
      knob.mousePressed((mouse) => {
        dragMouse.x = mouse.screenX
        dragMouse.y = mouse.screenY
        dragControl = knob
        const effectName = dragControl.attribute('data-effect-name')
        const soundName = activePad?.attribute('id')
        if (soundName) {
          const effect = sounds[soundName].effectValues[effectName]
          updateScreen(effect)
        }
      })
    })

    body.mouseReleased(() => {
      if (dragControl == null) return
      const effectName = dragControl.attribute('data-effect-name')
      const soundName = activePad?.attribute('id')
      if (effectName === 'bpm') {
        BPM.default = BPM.value
      } else if (soundName) {
        const effect = sounds[soundName].effectValues[effectName]
        effect.default = effect.value
      }
      dragControl = null
    })

    body.mouseMoved((mouse) => {
      if (dragControl != null) {
        const effectName = dragControl.attribute('data-effect-name')
        const soundName = activePad?.attribute('id')

        changeX = mouse.screenX - dragMouse.x // eslint-disable-line
        changeY = mouse.screenY - dragMouse.y // eslint-disable-line
        target = dragControl.elt // eslint-disable-line

        if (effectName === 'bpm') {
          updateEffectValues(BPM, changeY) // eslint-disable-line
          rotateKnob(BPM, target) // eslint-disable-line
          updateScreen(BPM)
          currentPart.setBPM(BPM.value)
          return
        }

        if (soundName) {
          const effectValues = sounds[soundName].effectValues
          const effect = effectValues[effectName]

          updateEffectValues(effect, changeY) // eslint-disable-line
          rotateKnob(effect, target) // eslint-disable-line
          updateScreen(effect)

          sounds[activePad.attribute('id')] // eslint-disable-line
          if (effectName === 'reverb' || effectName === 'decay') {
            // connect soundFile to reverb, process w/
            // 3 second reverbTime, decayRate of 2%
            sounds[soundName].effects.reverb.process(
              sounds[soundName].sound,
              effectValues.reverb.value,
              effectValues.decay.value,
              0
            )
          } else if (
            effectName === 'delay' ||
            effectName === 'feedback' ||
            effectName === 'filter'
          ) {
            // delay.process() accepts 4 parameters:
            // source, delayTime (in seconds), feedback, filter frequency
            sounds[soundName].effects.delay.process(
              sounds[soundName].sound,
              effectValues.delay.value * 0.01,
              effectValues.feedback.value * 0.01,
              effectValues.filter.value
            )
          } else if (effectName === 'pan') {
            sounds[soundName].sound.pan(effectValues.pan.value * 0.01)
          } else if (
            effectName === 'freq' ||
            effectName === 'res' ||
            effectName === 'amp'
          ) {
            sounds[soundName].effects.filter.freq(effectValues.freq.value)
            sounds[soundName].effects.filter.res(effectValues.res.value)
            sounds[soundName].effects.filter.amp(effectValues.amp.value)
          }
        }
      }
    })

    // end setup
  }
}
