'use strict'

export default {
  controller: {
    bpm: 120,
    screenValue: 0
  },
  main: {
    data: {
      looping: false,
      myPart: {},
      sounds: {},
      phrases: {},
      patterns: {}
    }
  },
  events: {
    data: [
      {
        front: new URL(
          './assets/events/IMG_6270.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/IMG_6283.jpg',
          import.meta.url
        ).toString()
      },
      {
        front: new URL(
          'url:./assets/events/IMG_7767.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/IMG_7664 Kopie.jpg',
          import.meta.url
        ).toString()
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_august_2023.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_october_2023.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_december_2023.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_december_20232.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_feb_2023_flyer_fin.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_feb_2023_flyer_fin2.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_flyer_februar_2024_002.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_flyer_februar_2024_0025.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_flyer_januar2024.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_flyer_januar20242.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_flyer_januar20242.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_juli_2023_flyer_fin.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_juli_2023_flyer_fin2.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_juni_2023_flyer_fin.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_juni_2023_flyer_fin2.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_maerz_2023_flyer_fin_fin.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_maerz_2023_flyer_fin_fin2.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_maerz_2023_flyer_fin_fin3.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_maerz_2023_flyer_fin_fin4.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_mai_2023_flyer_Fin.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_mai_2023_flyer_Fin2.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_november_fin_ra2.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_november_finfin.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_october_2023.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        front: new URL(
          'url:./assets/events/STAUB_october_20232.jpg',
          import.meta.url
        ).toString(),
        back: new URL(
          'url:./assets/events/STAUB_October_2023_002.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        src: new URL(
          'url:./assets/events/STAUB_October_2023_0022.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        src: new URL(
          'url:./assets/events/STAUB_September_2023_fin.jpg',
          import.meta.url
        ).toString(),
        archived: true
      },
      {
        src: new URL(
          'url:./assets/events/STAUB_September_2023_fin2.jpg',
          import.meta.url
        ).toString(),
        archived: true
      }
    ]
  }
}
