'use strict'

import { Link } from 'smbls'
import { TransitionTrigger } from './Transition'

export const Navigation = {
  extend: ['Flex'],
  tag: 'nav',

  props: () => {
    const location = window.location.pathname.split('/')[1]
    let fontFamily = 'ui'
    if (location === 'controller') {
      fontFamily = 'game'
    }
    return {
      gap: 'C3',
      order: -1,
      height: '100dvh',
      flow: 'column',
      align: 'start space-between',
      zIndex: 1000,
      padding: 'B B1',
      fontFamily: fontFamily,
      position: 'sticky',
      userSelect: 'none',
      top: '0'
    }
  },

  Logo: {
    extend: ['Flex'],
    props: {
      margin: '- - - -Y',
      height: 'C',
      overflow: 'hidden',
      flexAlign: 'center'
    }
  },

  Nav: {
    extend: [TransitionTrigger, 'Flex'],
    props: {
      margin: '- - auto',
      flow: 'column',
      gap: 'C+Y1',
      pointerEvents: 'none'
    },

    childExtend: {
      tag: 'a',
      props: ({ props }) => ({
        fontWeight: '500',
        lineHeight: 1,
        pointerEvents: 'auto',
        letterSpacing: '1.5px',
        fontFamily: 'def',
        opacity: '.35',
        fontSize: 'Y2',
        padding: 'Y Z',
        active: props.href === window.location.pathname,
        ':hover': {
          textDecoration: 'underline 2px',
          style: { textDecorationOffset: '3px' }
        },
        '.active': { opacity: '1' }
      }),
      on: {
        click: (ev, el, s) => {
          setTimeout(() => {
            Link.on.click(ev, el, s)
          }, 250)
        }
      }
    },

    $propsCollection: [{
      text: 'Events',
      href: '/events'
    }, {
      text: 'About',
      href: '/about'
    }, {
      text: 'Contact',
      href: '/contact'
    }]
  },

  Icons: {
    extend: 'Flex',

    props: {
      gap: 'X',
      margin: '- - -Z -Z',
      align: 'center',
      position: 'relative'
    },

    SquareButton_play: {
      props: ({ state }) => ({
        fontSize: 'B2',
        background: 'transparent',
        color: 'currentColor',
        class: 'play-button',
        icon: 'play',
        hide: state.playing,

        onClick: (ev, el, s) => {
          s.update({ playing: true })
        }
      })
    },

    SquareButton_Stop: {
      props: ({ state }) => ({
        fontSize: 'B2',
        background: 'transparent',
        color: 'currentColor',
        class: 'stop-button',
        icon: 'pause',
        hide: !state.playing,
        onClick: (ev, el, s) => {
          s.update({ playing: false })
        }
      })
    },

    SquareButton_sound: {
      fontSize: 'B2',
      icon: 'sound',
      background: 'transparent',
      color: 'currentColor',
      onClick: (ev, el, s) => {
        s.update({ volumeActive: !s.volumeActive })
      }
    },

    // Clear: {
    //   class: 'clear-button',
    //   boxSize: 'B1',
    //   fontSize: 'A1',
    //   icon: null,
    //   text: 'X'
    // }

    VolumeSlider: {
      position: 'absolute',
      left: '100%',
      margin: '0',
      opacity: '0',
      width: '0',
      overflow: 'hidden',
      transition: 'B defaultBezier',
      transitionProperty: 'opacity, width',
      '.volumeActive': {
        opacity: '1',
        width: 'D1'
      }
    }
  }
}

export const InfoButton = {
  extend: ['SquareButton', 'TooltipParent'],

  props: {
    background: 'transparent',
    color: 'white',
    Icon: {
      fontSize: 'B2',
      icon: 'info'
    }
  },

  TooltipHidden: {
    // position: 'top',
    title: 'Using drum machine',
    p: 'test'
  }
}
